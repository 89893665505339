import { memo, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from "react";

import { Pagination } from "@aws-amplify/ui-react";
import "./styles/Results.css";

import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { CenterContainer } from "../tabs/center/containers/CenterContainer";
import { ResearcherContainer } from "../tabs/researcher/containers/ResearcherContainer";
import TechnologyContainer from "../tabs/tecnologies/containers/TechnologyContainer";
import ServiceContainer from "../tabs/services/containers/ServiceContainer";
import EquipamentContainer from "../tabs/equipament/containers/EquipamentContainer";

import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";
import { Context } from "global/reduxState/store";
import { TabsMenu } from "../components/TabsMenu";
import { FilterAction } from "global/reduxState/interfaces/ActionsType";
import { paginator } from "global/utils/paginator";
import { axiosIaInstance, axiosInstance } from "global/utils/axios";

import qs from 'query-string';
import { FacetType } from "Result/interfaces/Researcher";
import { areDifferents, getWithExpiry, getWithoutExpiry, setWithoutExpiry } from "global/utils/localStorageUtils";
import { WhyResponse } from "Result/interfaces/WhyResponse";
import Result from '../index';

interface Props {
	query: string;
	page: number;
}

export enum Path {
	reseacher = "/researcher",
	hub = "/hub",
	service = "/service",
	technology = "/technology",
	equipament = "/equipament",
}

export const Results = ({ query, page}: Props) => {
	const [{ facets, typeSearch }, dispatch] = useContext(Context);
	const [firstExecution, setFirstExecution] = useState(true);
	const [changeTypeSearch, setchangeTypeSearch] = useState(true)

	let { url, path } = useRouteMatch();

	const [loadData, setLoadData] = useState(false)
	const location = useLocation();
	const pathname = location.pathname;
	const [stringPath, setStringPath] = useState(location.pathname + location.search);
	const previousStringPathRef = useRef(stringPath);
	const previousStringQueryRef = useRef(query);
	const previousStringPathnameRef = useRef(location.pathname);

	const university = useGetFirstUrlParam();
	const [currentUrl, setCurrentUrl] = useState<string>(window.location.href);

	const [results, setResults] = useState<any>(undefined);

	const getDefaultPathname = (pathname: string): Path => {
		if (pathname.includes("/hub")) {
			return Path.hub;
		}
		if (pathname.includes("/service")) {
			return Path.service;
		}
		if (pathname.includes("/technology")) {
			return Path.technology;
		}
		if (pathname.includes("/equipament")) {
			return Path.equipament;
		}
		return Path.reseacher;
	};

	const defaulPathName = getDefaultPathname(pathname);

	const [endPoint, setEndpoint] = useState<Path>(defaulPathName);

	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const [firstResearcherNumber, setFirstResearcherNumber] = useState(0);
	const [lastResearchernumber, setLastResearchernumber] = useState(0);
	const [total_pages, setTotal_pages] = useState(0);
	const [total_results, setTotal_results] = useState(0);

	const history = useHistory();

	const clearEmpyFilters = (facets: FacetType) => {
		const facetsTitles = Object.keys(facets);
		for (let index = 0; index < facetsTitles.length; index++) {
			const facetTitle = facetsTitles[index];
			facets[facetTitle][0].data = facets[facetTitle][0].data.filter((facet) => facet.value !== "");
			if (facets[facetTitle][0].data.length === 0) {
				delete facets[facetTitle];
			}
		}
		return facets;
	};

	const prepareFilters = () => {
		const searchParams: string = decodeURIComponent(location.search);

	    const paramsFilter = searchParams.split('&').slice(2).join('&');

        return paramsFilter;
	};

	useEffect(()=>{
		setchangeTypeSearch(true)
	},[typeSearch])
	
	useEffect(() => {
		let updateData = loadData;
		const newStringPath = location.pathname + location.search;	
		// Realiza acciones específicas si el valor de stringPath cambia
		if(firstExecution){
			setLoadData(true)
			setFirstExecution(false)
			updateData = true
		}
		console.log(currentUrl)
		console.log(window.location.href)
		if(currentUrl !== window.location.href){
			console.log("here")
			setCurrentUrl(window.location.href)
			setLoadData(true);
			updateData = true;
			console.log(loadData)
		}
		if (stringPath !== previousStringPathRef.current) {
			previousStringPathRef.current = stringPath;
			setStringPath(newStringPath);
			setLoadData(true);
			updateData = true;
		}
		if(query !== previousStringQueryRef.current){
			previousStringQueryRef.current = query;
			setLoadData(true);
			updateData = true;
		}

		if(location.pathname !== previousStringPathnameRef.current){
			previousStringPathnameRef.current = location.pathname;
			setLoadData(true);
			updateData = true;
		}

		if(changeTypeSearch){
			setchangeTypeSearch(false)
			setLoadData(true);
			updateData = true;
		}

		console.log(loadData)
		if(!updateData){
			return
		}
		/* if (stringPath !== previousStringPathRef.current || firstExecution) {
			
		}else{
			console.log("aers2")
			return;
		} */
		const universitiesChange = areDifferents('universitiesSelected','prevUniversitiesSelected');
		
		
		setLoading(true);
		const fetchData = async () => {
			try {
				const paramsFilter = prepareFilters();
				let sucess: any;
				let data: any;
				if(typeSearch==="ia" && university==="caps"){
					sucess = await axiosIaInstance.get(`${endPoint}/${university}?query=${query}&n_current=${page}&${paramsFilter}`);
					data = sucess.data.response[0]
					let promises = data?.results.map(async (item: any) => {
						const resultsia = await axiosIaInstance.post<WhyResponse>(`why`, { "prompt": item["prompt"]["raw"] })
						let nota = +resultsia.data.data.nota.replace(/\s+/g, '');
						let escala = ""
						if(nota<=3) escala = "Bajo";
						else if(nota>=4 && nota<=7) escala = "Medio";
						else if(nota>=8) escala = "Alto";
						return { ...item, withIa: true, nota: ` ${escala}`, razon: resultsia.data.data.razon}
					})
					data.results = await Promise.all(promises)
					const orderMap: { [key: string]: number } = {
						" Alto": 0,
						" Medio": 1,
						" Bajo": 2,
					};
					
					data.results.sort((a: any, b: any) => {
						const notaA = a.nota;
						const notaB = b.nota;
						
						return orderMap[notaA] - orderMap[notaB];
					});
				}else{
					sucess = await axiosInstance.get(`${endPoint}/${university}?query=${query}&n_current=${page}&${paramsFilter}`);
					data = sucess.data.response[0];
					data.results = data?.results.map((item: any) =>{
						return { ...item, withIa: false}
					})
				} 
					
				setResults(data);
				setError(false);
				setLoading(false);

				let { firstResearcherNumber, lastResearchernumber, total_pages, total_results } = paginator(
					data?.meta?.page,
					data,
					page
				);

				
				if(typeSearch === "keywords"){
					let data2 = sucess.data.response[1];
					let facetsResponse = clearEmpyFilters(data2.facets as FacetType);
	
					let facetToShow: any = {};
					if(!universitiesChange && facets['universidad']){
						facetToShow = facets;
					}else{
						if(facets['universidad']){
							facetToShow = {
								...facetsResponse,
								universidad: facets["universidad"],
							}
						}else{
							facetToShow = facetsResponse;
						}
						
					}
					const objKeys = Object.keys(facetToShow);
					objKeys.forEach((key) => {
						facetToShow[key][0].data = facetToShow[key][0].data.map((element: any) =>{
							const indexOfValue = facetsResponse[key][0].data.map(e => e.value).indexOf(element.value);
							if(indexOfValue !== -1){
								element.checked = facetsResponse[key][0].data[indexOfValue].checked;
							}else{
								element.checked = false;
							}

							return element;
						})
					});
					dispatch({ type: "[filter] load filters", payload: facetToShow });
					setWithoutExpiry('prevUniversitiesSelected', getWithoutExpiry('universitiesSelected'));
				}

				
				setFirstResearcherNumber(firstResearcherNumber);
				setLastResearchernumber(lastResearchernumber);

				setTotal_pages(total_pages);
				setTotal_results(total_results);

			} catch (error) {
				setError(true);
				setResults(undefined);
				setLoading(false);
			}
			
		}
		
		fetchData();
		setLoadData(false)

	}, [query, page, location.search, location.pathname, changeTypeSearch]);

	// usecallback to avoid re-rendering
	const handleChangeTabs = useCallback(
		(e: SyntheticEvent, tab: Path) => {
			if (loading) {
				e.preventDefault();
				return;
			}
			//clearing filter on each change of tabs
			const action: FilterAction = {
				type: "[filter] reset filters",
			};
			setWithoutExpiry('universitiesSelected', []);
			setWithoutExpiry('prevUniversitiesSelected', '');
			setResults(undefined);
			dispatch(action);
			setEndpoint(tab);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loading]
	);

	// We set the data to its correspont schema
	// useEffect(() => {
	// 	setResults(data);
	// }, [data]);

	/**
	 * @description updating the response by search and setting the posibles filters by the data searched
	 */
	// useEffect(() => {
	// 	// if we have no more result we come back to the first page

	// 	if (data?.results?.length === 0) {
	// 		setCurrentPage(1);
	// 	}
	// }, [data?.results]);

	// Set paginator to 1 when query or tab change
	// useEffect(() => {
	// 	setCurrentPage(1);
	// }, [query, pathname]);

	/**
	 * @description reset current page when filters change
	 */
	// useEffect(() => {
	// 	setCurrentPage(1);
	// }, [fieldsSelected]);

	const handlePageChange = (next: number, prev: number) => {
		const queryParams = qs.parse(location.search, {sort: false});

		queryParams.n_page = next.toString();

		history.push({ search: qs.stringify(queryParams, {sort: false})} );
	};

	const changePage = (number: number) =>{
		const queryParams = qs.parse(location.search, {sort: false});

		const newQueries = { ...queryParams, n_page:page + number};

		history.push({ search: qs.stringify(newQueries,  {sort: false}) });

	}

	return (
		<section className="container-result-search">
			<div className="column">
				<p className="list-result">
					Mostrando {firstResearcherNumber || 0} - {lastResearchernumber || 0} de {total_results || 0}
				</p>
				<TabsMenu handleChangeTabs={handleChangeTabs} url={url} />
				{/* this is a sub-route that's why it doesn't have Router parent */}
				<Switch>
					<Route exact path={path}>
						<ResearcherContainer error={error} loading={loading} query={query} data={results?.results} />
					</Route>
					<Route path={`${path}/hub`}>
						<CenterContainer error={error} loading={loading} query={query} data={results?.results} />
					</Route>
					<Route path={`${path}/technology`}>
						<TechnologyContainer error={error} loading={loading} query={query} data={results?.results} />
					</Route>
					<Route path={`${path}/service`}>
						<ServiceContainer error={error} loading={loading} query={query} data={results?.results} />
					</Route>
					<Route path={`${path}/equipament`}>
						<EquipamentContainer error={error} loading={loading} query={query} data={results?.results} />
					</Route>
				</Switch>
				<Pagination
					currentPage={page}
					totalPages={total_pages!}
					siblingCount={1}
					onChange={handlePageChange}
					onNext={() => changePage(1)}
					onPrevious={() => changePage(-1)}
				/>
			</div>
		</section>
	);
};

export const ResultsMemorized = memo(Results);
