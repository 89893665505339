import styles from "./styles/SearchBox.module.css";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "global/reduxState/store";
import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";

import qs from 'query-string';
import { areDifferents, getWithoutExpiry, setWithoutExpiry } from "global/utils/localStorageUtils";
import { FormControlLabel, Switch } from "@mui/material";

interface Props {
	queryParam?: string | (string | null)[] | null;
	setQuery: any;
}

export const SearchBox = ({ queryParam, setQuery }: Props) => {
	const { register, handleSubmit } = useForm({
		defaultValues: {
			query: queryParam || "",
		},
	});

	const history = useHistory();

	const location = useLocation();

	const [stateContext, dispatch] = useContext(Context);
	const [switchValue, setSwitchValue] = useState(false);
	const firstParam = useGetFirstUrlParam();


	useEffect(() => {
		setSwitchValue(stateContext.typeSearch === "ia" ? true : false)
		const queryChange = areDifferents('query', 'prevQuery');

		if (!queryChange) {

			history.push({ search: location.search });
		} else {
			const newQueries = { query: queryParam, n_page: 1 };
			history.push({ search: qs.stringify(newQueries, { sort: false }) });

			setWithoutExpiry('prevUniversitiesSelected', 'undefined');
			setWithoutExpiry('universitiesSelected', '');

			dispatch({ type: "[filter] reset filters" });
		}

		setWithoutExpiry('prevQuery', getWithoutExpiry('query'));
		setWithoutExpiry('query', queryParam);

	}, [queryParam, history]);

	const onSubmit = ({ query }: any) => {
		setWithoutExpiry('prevQuery', getWithoutExpiry('query'));
		setWithoutExpiry('query', query);
		setQuery(query);
	}
	const handleSwitch = (e: any) => {
		const newValue = e.target.checked;
		setSwitchValue(newValue)
		if (newValue) {
			dispatch({ type: "[typesearch] change typesearch", payload: { typeSearch: 'ia' } })
		} else {
			dispatch({ type: "[typesearch] change typesearch", payload: { typeSearch: 'keywords' } })
		}
	};
	return (
		<section className={styles.containerSearchAdvanced}>
			{
				firstParam === "caps" &&
				<FormControlLabel
					value="start"
					control={<Switch color="primary" />}
					label="Búsqueda con IA"
					labelPlacement="start"
					checked={switchValue}
					onChange={handleSwitch}
				/>
			}
			<form onSubmit={handleSubmit(onSubmit)}>
				<input
					type="text"
					className={styles.search}
					placeholder="Buscar por nombre o palabra clave"
					{...register("query")}
				/>
			</form>
		</section>
	);
};
